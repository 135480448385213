<template>
  <div>
    <div
      class="column-row column-row-3"
      style="margin-bottom: 80px; height: 40px"
    >
      <div class="column column-large first last">
        <div class="column-top">
          <h1 id="ctl00_ContentPlaceHolderUniverse_Header">Please remember</h1>
        </div>
        <div class="column-content">
          <div id="ctl00_ContentPlaceHolderUniverse_IntroText">
            At The LEGO Group, we believe it’s not whether you win or lose but
            how you play the game that’s really important. All players should
            honor the LEGO Universe Code of Conduct at all times.
          </div>
        </div>
      </div>
    </div>
    <text-module>
      <div class="negative-row">
        <div class="t-list" id="SafetyTipsList">
          <safety-tips-list :tips="tips"></safety-tips-list>
        </div>
      </div>
    </text-module>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SafetyTipsList from "@/components/modules/SafetyTipsList.vue";
import { CODE_OF_CONDUCT } from "@/data/safety-tips";

export default defineComponent({
  components: { SafetyTipsList },
  setup() {
    return { tips: CODE_OF_CONDUCT };
  },
});
</script>
