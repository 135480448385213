
import { defineComponent } from "vue";
import SafetyTipsList from "@/components/modules/SafetyTipsList.vue";
import { CODE_OF_CONDUCT } from "@/data/safety-tips";

export default defineComponent({
  components: { SafetyTipsList },
  setup() {
    return { tips: CODE_OF_CONDUCT };
  },
});
